export type Size = {
  length: number
  width: number
}

export type FormattedSize = {
  length: string
  width: string
}

export enum Variant {
  SingleColor = 'SINGLE_COLOR',
  DualColor = 'DUAL_COLOR',
  Open = 'OPEN',
}

type CalculatorState = {
  variant: Variant
  scale: number
  inputSize: Size
  outputSize: Size
  formattedOutputSize: FormattedSize
  price: number
  offer: string
}

export default CalculatorState
