import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import app from './firebase'

interface AuthContextType {
  currentUser: firebase.User | null
}

interface Props {
  children: React.ReactNode
}

const AuthContext = React.createContext<AuthContextType>({ currentUser: null })

function AuthProvider(props: Props) {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null)
  const [pending, setPending] = useState<boolean>(true)

  useEffect(() => {
    app.auth().onAuthStateChanged(user => {
      setCurrentUser(user)
      setPending(false)
    })
  }, [])

  if (pending) {
    return <>Please wait…</>
  }

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }
