import React from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

type Props = {
  label: string
  placeholder: string
  unit: string
  value?: string | number
  readOnly?: boolean
  onChange?: (value: string) => void
}

const UnitField: React.FC<Props> = ({
  label,
  placeholder,
  unit,
  value,
  readOnly,
  onChange,
}) => {
  const internalOnChange = (value: string) => {
    if (onChange) {
      onChange(value)
    }
  }
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <Form.Control
          type='text'
          placeholder={placeholder}
          value={value}
          onChange={e => internalOnChange(e.target.value)}
          readOnly={readOnly || false}
        />
        <InputGroup.Append>
          <InputGroup.Text>{unit}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  )
}

export default UnitField
