import React, { useReducer } from 'react'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Button from 'react-bootstrap/Button'

import CalculatorState, { Variant } from './Reducer/CalculatorState'
import CalculatorReducer from './Reducer/CalculatorReducer'
import UnitField from './Components/UnitField'

const Calculator: React.FC = () => {
  const [state, setState] = useReducer(CalculatorReducer, {
    variant: Variant.SingleColor,
    scale: 160,
    inputSize: {
      length: 0,
      width: 0,
    },
    outputSize: {
      length: 0,
      width: 0,
    },
    formattedOutputSize: {
      length: '0',
      width: '0',
    },
    price: 85,
    offer: '',
  })

  const setLength = (length: string) => {
    setState({
      type: 'setLength',
      length: Number(length.replace(',', '.')),
    })
  }

  const setWidth = (width: string) => {
    setState({
      type: 'setWidth',
      width: Number(width.replace(',', '.')),
    })
  }

  const setScale = (scale: number) => {
    setState({
      type: 'setScale',
      scale,
    })
  }

  const setPrice = (input: string) => {
    setState({
      type: 'setPrice',
      price: Number(input.replace(',', '.')),
    })
  }

  const copyOffer = async () => {
    // workaround from https://github.com/microsoft/TypeScript/issues/33923#issuecomment-743062954
    const permissionName = 'clipboard-write' as PermissionName
    const { state: permission } = await navigator.permissions.query({
      name: permissionName,
    })
    if (permission === 'granted' || permission === 'prompt') {
      await navigator.clipboard.writeText(state.offer)
    }
  }

  return (
    <Container className='p-3'>
      <h1>Angebotsrechner</h1>
      <Form>
        <UnitField
          label='Länge'
          placeholder='Länge in Metern'
          unit='m'
          onChange={setLength}
        />
        <UnitField
          label='Breite'
          placeholder='Breite in Metern'
          unit='m'
          onChange={setWidth}
        />

        <Form.Group>
          <Form.Label>Variante</Form.Label>
          <Form.Control as='select' custom>
            <option id={Variant.SingleColor}>Einfarbig</option>
            <option id={Variant.DualColor}>Zweifarbig</option>
            <option id={Variant.Open}>Zum Öffnen</option>
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Maßstab</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>1</InputGroup.Text>
              <InputGroup.Text>:</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type='text'
              placeholder='Maßstab'
              value={state.scale}
              onChange={e => setScale(Number(e.target.value))}
            />
            <DropdownButton
              as={InputGroup.Append}
              variant='outline-secondary'
              title='Voreinstellungen'
              id='input-group-dropdown-2'
            >
              <Dropdown.Item onClick={() => setScale(160)}>160</Dropdown.Item>
              <Dropdown.Item onClick={() => setScale(120)}>120</Dropdown.Item>
              <Dropdown.Item onClick={() => setScale(100)}>100</Dropdown.Item>
              <Dropdown.Item onClick={() => setScale(80)}>80</Dropdown.Item>
            </DropdownButton>
          </InputGroup>
        </Form.Group>

        <UnitField
          label='Preis'
          placeholder='Preis'
          unit='€'
          value={state.price}
          onChange={setPrice}
        />

        <h2>Angebot</h2>

        <UnitField
          label='Minihaus Länge'
          placeholder='Minihaus Länge'
          unit='cm'
          value={state.formattedOutputSize.length}
          readOnly
        />

        <UnitField
          label='Minihaus Breite'
          placeholder='Minihaus Breite'
          unit='cm'
          value={state.formattedOutputSize.width}
          readOnly
        />

        <Form.Group>
          <Form.Label>Text</Form.Label>
          <Form.Control as='textarea' rows={3} readOnly value={state.offer} />
        </Form.Group>
      </Form>

      <Button onClick={e => copyOffer()} block>
        Kopieren
      </Button>
    </Container>
  )
}

export { Calculator }
