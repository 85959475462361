import React, { useCallback } from 'react'
import { RouteComponentProps } from 'react-router'
import app from '../../firebase'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import './Login.scss'

type Props = RouteComponentProps

const Login: React.FC<Props> = ({ history }) => {
  const handleLogin = useCallback(
    async event => {
      event.preventDefault()
      const { email, password } = event.target.elements
      try {
        await app.auth().signInWithEmailAndPassword(email.value, password.value)
        history.push('/')
      } catch (error) {
        alert(error)
      }
    },
    [history]
  )

  return (
    <Container id='LoginComponent' className='p-3'>
      <h1>Anmelden</h1>
      <Form onSubmit={handleLogin}>
        <Form.Group controlId='email'>
          <Form.Label>E-Mail</Form.Label>
          <Form.Control type='email' placeholder='E-Mail' />
        </Form.Group>

        <Form.Group controlId='password'>
          <Form.Label>Passwort</Form.Label>
          <Form.Control type='password' placeholder='Passwort' />
        </Form.Group>

        <Button variant='primary' type='submit'>
          Anmelden
        </Button>
      </Form>
    </Container>
  )
}

export { Login }
