import CalculatorState from './CalculatorState'
import CalculatorAction from './CalculatorAction'

const formatNumber: (input: number) => string = input =>
  input.toLocaleString('de-DE')

/* Converts a value in meters (hence the 1000)
 * to a value in cm (hence the 10)
 * based on a given scale (1:x) */
const convert: (input: number, scale: number) => number = (input, scale) =>
  (input * 1000 * (1 / scale)) / 10

const makeOffer: (state: CalculatorState) => string = state => {
  return (
    `Dein Haus im ${state.scale}:1 minihaus Format ` +
    `wäre ${state.formattedOutputSize.length}cm * ${state.formattedOutputSize.width}cm groß und ` +
    `würde ${state.price} € kosten.`
  )
}

const CalculatorReducer: (
  state: CalculatorState,
  action: CalculatorAction
) => CalculatorState = (state, action) => {
  let newState = state
  switch (action.type) {
    case 'setScale': {
      newState = {
        ...state,
        scale: action.scale,
        outputSize: {
          ...state.outputSize,
          length: convert(state.inputSize.length, action.scale),
          width: convert(state.inputSize.width, action.scale),
        },
      }
      break
    }
    case 'setLength': {
      newState = {
        ...state,
        inputSize: {
          ...state.inputSize,
          length: action.length,
        },
        outputSize: {
          ...state.outputSize,
          length: convert(action.length, state.scale),
        },
      }
      break
    }
    case 'setWidth': {
      newState = {
        ...state,
        inputSize: {
          ...state.inputSize,
          width: action.width,
        },
        outputSize: {
          ...state.outputSize,
          width: convert(action.width, state.scale),
        },
      }
      break
    }
    case 'setPrice': {
      newState = {
        ...state,
        price: action.price,
      }
      break
    }
    default: {
      break
    }
  }

  // apply formatting

  newState = {
    ...newState,
    formattedOutputSize: {
      ...newState.formattedOutputSize,
      length: formatNumber(newState.outputSize.length),
      width: formatNumber(newState.outputSize.width),
    },
  }

  // make offer

  return {
    ...newState,
    offer: makeOffer(newState),
  }
}

export default CalculatorReducer
