import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Navigation from './Components/Navigation'
import { Home, Login, Orders, Calculator } from './Pages'
import { AuthProvider } from './Authentication'
import PrivateRoute from './PrivateRoute'
import './App.css'

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Navigation />
        <div>
          <PrivateRoute exact path='/' component={Home} />
          <Route exact path='/login' component={Login} />
          <PrivateRoute exact path='/orders' component={Orders} />
          <PrivateRoute exact path='/calculator' component={Calculator} />
        </div>
      </Router>
    </AuthProvider>
  )
}

export default App
