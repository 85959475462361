import React from 'react'
import Container from 'react-bootstrap/Container'

const Orders: React.FC = () => {
  return (
    <Container className='p-3'>
      <h1>Orders</h1>
    </Container>
  )
}

export { Orders }
