import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import app from '../firebase'
import { AuthContext } from '../Authentication'

const Navigation: React.FC<{}> = () => {
  const { currentUser } = useContext(AuthContext)

  const handleLogout = () => {
    app.auth().signOut()
  }

  return (
    <Navbar bg='dark' variant='dark' expand='lg' sticky='top'>
      <Container className='px-3'>
        <Navbar.Brand>minihaus admin</Navbar.Brand>
        {!!currentUser ? (
          <>
            <Navbar.Toggle aria-controls='main-navigation-bar' />
            <Navbar.Collapse id='main-navigation-bar'>
              <Nav className='mr-auto'>
                <Link to='/' className='nav-link'>
                  Übersicht
                </Link>
                <Link to='/orders' className='nav-link'>
                  Bestellungen
                </Link>
                <Link to='/calculator' className='nav-link'>
                  Angebotsrechner
                </Link>
              </Nav>
              <Nav>
                <Button variant='danger' onClick={handleLogout}>
                  Logout
                </Button>
              </Nav>
            </Navbar.Collapse>
          </>
        ) : null}
      </Container>
    </Navbar>
  )
}

export default Navigation
