import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from './Authentication'

interface Props {
  component: React.FC
  path: string
  exact: boolean
}

const PrivateRoute: React.FC<Props> = ({ component, path, exact }) => {
  const { currentUser } = useContext(AuthContext)
  return !!currentUser ? (
    <Route path={path} exact={exact} component={component}></Route>
  ) : (
    <Redirect to={'/login'} />
  )
}

export default PrivateRoute
